export const API_BASE_PATH = import.meta.env.VITE_API_BASE_PATH;

export const captchaSiteKey_v3 = "6LczwRwbAAAAAJ5bJGbQQBpXG9lqfFeAyBBjdUPl";

// Authentication
export const USER_PRE_SIGN_IN = "/auth/pre_signin";
export const USER_SIGN_IN = "/auth/signin";
export const USER_SSO_SIGN_IN = "/auth/sso-login";
export const DEMO_USER_SIGN_IN = "/demo-auth/signin";
export const USER_SIGN_UP = "/auth/signup";
export const USER_RESEND_CONFIRMATION = "/auth/resend-confirmation";
export const USER_FORGOTPASSWORD_URL = "/auth/forgot-password";
export const USER_CONFIRM_FORGOTPASSWORD_URL = "/auth/confirm-forgot-password";
export const USER_RESET_PASSWORD_URL = "/auth/reset_temp_password";
export const USER_REFRESH_TOKEN_URL = "/auth/refresh-token";
export const USER_CHECK_API_PERMISSION = "/auth/check_permission";
export const USER_LOGOUT_A_SESSION = "/auth/logoutasession";
export const SSO_CONFIGURATIONS = "auth/sso-configurations/";
export const SSO_CONFIGURATION = "auth/sso-configuration/";
export const SSO_ACTIVE_SETTINGS =
  "auth/sso-profile/azure-ad-saml/active-settings";
export const SSO_AD_ACTIVE_SETTINGS =
  "auth/sso-profile/azure-ad-saml/active-settings";
export const SSO_OKTA_ACTIVE_SETTINGS =
  "auth/sso-profile/okta-saml/active-settings";
export const SSO_ONELOGIN_ACTIVE_SETTINGS =
  "/auth/sso-profile/one-login-saml/active-settings";
export const SSO_ONELOGIN_APPLICATION_ROLES =
  "/auth/sso-profile/one-login-saml/application-roles";
export const SSO_APPLICATION_ROLES =
  "auth/sso-profile/azure-ad-saml/application-roles";
export const SSO_ENABLED_URL = "auth/sso/enabled";
export const SSO_PROFILES = "auth/sso-profiles";
export const COPILOT_ACCESS_REQUEST = "/customersupport/submitrequest/";
export const COPILOT_FEATURE_ENABLE = "/copilot/feature-request";

export const ENABLE = "/enable";
export const DISABLE = "/disable";

// Account Management
export const ACCOUNT_REMOVE_URL = "initiate-resource-removal";
export const CUSTOMER_ACCOUNT = "/customeraccount/";
export const ACCOUNTS_TEMPLATES = "/accounts/onboarding-templates";
export const CUSTOMER_ACCOUNT_STATE = "/customeraccount/account_state";
export const CUSTOMER_REGION_STATE = "/customeraccount/region_state";
export const CUSTOMER_ACCOUNT_STATUS = "/customeraccount/status";
export const CUSTOMER_ACCOUNT_CONFIGURE_REGIONS_URL =
  "/customeraccount/configure_regions";
export const CUSTOMER_ACCOUNT_ORGANIZATION = "/customeraccount/organizations";
export const CUSTOMER_ACCOUNT_MEMBER_ONBOARDING =
  "/customeraccount/accounts/onboard_member_account";
export const CUSTOMER_ACCOUNT_MEMBER_OFFBOARDING =
  "/initiate_member_account_removal";
export const CUSTOMER_ACCOUNT_BASE = "customeraccount/";
export const CUSTOMER_ACCOUNT_LOGO = "/customeraccount/logo";
export const HIERARCHY_SUFFIX = "/hierarchy";

//GLOBAL CONFIGURATION
export const GLOBAL_REGION_LIST_URL = "/v1/mc/list-regions";
export const AZURE_GLOBAL_REGION_LIST_URL = "/resource/regions";
export const GLOBAL_USER_DATA = "auth/user-data";

//EC2 MANAGEMENT
export const EC2_INSTANCE_LIST_URL = "/compute/instances";
export const EC2_INSTANCE_BASE_URL = "/compute/instance";
export const WORKSPACES_BASE_URL = "/compute/workspaces";
export const NOTEBOOK_INSTANCES_BASE_URL = "/compute/notebook-instance";
export const NOTEBOOK_INSTANCE_PRESIGNED_URL = "/presigned-url";
export const EC2_INSTANCE_SESSIONS_LIST_URL = "/compute/sessions/";
export const EC2_INSTANCE_SESSION_URL = "/compute/session/";
export const EC2_INSTANCE_SESSION_START_URL = "/compute/session/start/";
export const EC2_INSTANCE_SESSION_RESUME_URL = "/compute/session/resume/";
export const EC2_INSTANCE_SESSION_TERMINATE_URL = "/compute/session/terminate/";
export const EC2_SNAPSHOT_INSTANCE_URL = "/storage/snapshot";
export const CONVERT_DAY2_MANAGED = "/convert-day2-managed";
export const TYPES = "/types";

//PATCH MANAGEMENT - PATCHES
export const PATCHES_PATCHLIST_URL = "/v1/windows/patches";
export const PATCHES = "patches";
export const DSS_STATE = "dss-states";

//PATCH MANAGEMENT - PATCH POLICIES
export const PATCH_POLICIES_LIST_URL = "/compute/patchpolicies";
export const PATCH_POLICIES_DETAILS_URL = "/compute/patchpolicy";

//DESIRED STATE MANAGEMENT - DESIRED STATE POLICIES
export const DESIRED_STATE_POLICIES_LIST_URL = "/compute/dss/policies";

//DESIRED STATE ON SAVE AND EDIT
export const DESIRED_STATE_POLICY_SAVE_URL = "/compute/dss/policies/";

//AMI MANAGEMENT
export const AMI_LIST_URL = "/compute/images";
export const AMI_BASE_URL = "/compute/image";

//VOLUMES
export const VOLUMES_BASE_URL = "/storage/volumes";

//TASKS
export const SCHEDULED_TASKS_LIST_URL = "/scheduler/schedules";
export const JOBS_LIST_URL = "/scheduler/jobs";
export const JOB_SCHEDULE_BASE_URL = "/scheduler/job/";
export const SCHEDULE_BASE_URL = "/scheduler/schedule/";
export const JOBS_BASE_URL = "/jobs";
export const TASKS_BASE_URL = "/tasks";
export const CUSTOM_TASKS_BASE_URL = "/task/";
export const TASK_BASE_URL = "/task";
export const TASK_EXECUTE = "/execute/";
export const TASK_OUTPUT_URL = "/scripts/output";
export const TASK_OUTPUT_LOGS = "logs";
export const TASK_SCHEDULE_BASE_URL = "scheduler/task/";
export const RE_ORDER = "re-order";
export const QUICK_ACTION = "quick-action";
export const TASK_DETAILS = "/app/automation/tasks/task-details";

//INTEGRATIONS
export const INTEGRATIONS_BASE_URL = "/integrations";
export const CUSTOMER_DOMAINS = "auth/customer-domains/";
export const CUSTOMER_DOMAIN = "auth/customer-domain/";
export const VERIFY = "verify";
export const SUMMARY = "/summary";
export const SPECIFICATION = "/specification";
export const USAGES = "usages";

// PATCH MANAGEMENT - SERVER GROUPS
export const SERVER_GROUPS_BASE_URL = "/compute/servergroups";
export const SERVER_GROUP_BASE_URL = "/compute/servergroup/";
export const PATCH_POLICY_SAVE_URL = "/compute/patchpolicy/";
export const PATCH_SCAN_TASK_REPORT = "/compute/os-patch-details/patch_summary";
export const PATCH_SCAN_TASK_COMPLIANCE_ITEMS =
  "/compute/os-patch-details/patch_compliance_items";
export const PATCH_SCAN_TASK_EXPORT = "/compute/os-patch-details/export-report";

// ORGANIZATION
export const ORG_BASE_URL = "/org/";
export const ORGANIZATION_BASE_URL = "/org/organization/";
export const ORGANIZATIONS_BASE_URL = "/org/organizations/";
export const ORGANIZATION_SUMMARY_URL = "/org/onboarding-summary";
export const ORG_INVENTORY_SUMMARY = "/org/inventory-summary";
export const ORG_COST_OPTIMIZATION_SUMMARY = "/org/cost-optimization";
export const FINDING_SUMMARY_URL = "/org/findings-summary";
export const ORG_GOVERNANCE_SUMMARY = "org/resource-governance-summary";
export const DEPARTMENT_BASE_URL = "/department/";
export const DEPARTMENTS_BASE_URL = "/departments/";
export const ENVIRONMENT_BASE_URL = "/environment/";
export const ENVIRONMENTS_BASE_URL = "/environments/";
export const APPLICATION_BASE_URL = "/application/";
export const APPLICATIONS_BASE_URL = "/org/applications";
export const APP_ALERTS_URL = "/org/application/alerts";
export const ORG_APPLICATION_BASE_URL = "/org/application/";
export const RESOURCES_BASE_URL = "/resources";
export const RESOURCE_BASE_URL = "/resource";
export const SUMMARY_BASE_URL = "/summary";
export const RESOURCES_LIST_URL = "/org/resources";
export const RESOURCES_LIST_PAGINATED_URL = "/org/resources_paginated";
export const ORG_RESOURCE_TAGS_URL = "/org/resources/tags";
export const RESOURCE_EXIST_URL = "/org/does_resource_exist";
export const RESOURCES_EXIST_URL = "/org/do_resources_exist";
export const NOTIFICATIONS_BASE_URL = "/notifications";
export const MARK_ALL_READ_URL = "mark-all-read";
export const OPENED_URL = "opened";
export const RESOURCE_TAGS_URL = "/org/resourcetags";
export const RESOURCE_TAGS_COUNT_URL = "/org/resourcetags_count";
export const MONITORING_URL = "monitoring";
export const MONITORING_STATISTICS_URL = "monitoring/statistics";
export const RESOURCE_TYPES = "resource-types";
export const ORG_DEPARTMENT_BASE_URL = "/org/department/";
export const TENANT_CATEGORIES = "categories";

//REPORTS
export const REPORTS_BASE_URL = "/compute/reports";
export const OS_PATCH_REPORT_URL = "/compute/os-patch-report";
export const REPORT_EXPORTS_URL = "/reports/exports";
export const REPORTS_BASE_V2_URL = "/org/reports/";
export const REPORTS_URL = "reports";
export const OPTIMIZATION_REPORT_URL = "cost-optimization";
export const INVENTORY_REPORT_URL = "inventory-summary";
export const DSS_REPORT_URL = "/compute/dss-report";
export const REPORT_SPEND_BREAKDOWN_DETAILS =
  "/cost/reports/spend-breakdown-summary";

//BLUEPRINTS
export const BLUEPRINTS_LIST_URL = "/blueprints/list";
export const BLUEPRINTS_BASE_URL = "/blueprints/";
export const BLUEPRINTS_LAUNCH_URL = "/launch/";
export const BLUEPRINTS_TEMPLATE_URL = "/template/";
export const BLUEPRINT_GET_FILES_URL = "/get_files";
export const BLUEPRINTS_STACKS_URL = "/stacks/";
export const BLUEPRINTS_HISTORY_URL = "/blueprints/history/";
export const BLUEPRINTS_CATEGORY = "/blueprints/category";
export const BLUEPRINTS_VALIDATE = "/validate";
export const DIAGRAM_URL = "/diagram";
export const BLUEPRINT_ID_URL = "/blueprints/blueprint/";
export const BLUEPRINT_CONFIG_URL = "/blueprints/config/";
export const ACTIONS_URL = "/actions/";
export const BLUEPRINTS_PERMISSIONS_URL = "/permission_check";
export const BLUEPRINTS_REVIEW_URL = "/blueprints/review";
export const ROUTINE_TASKS_URL = "/routine_tasks";
export const IAM_ROLE_POLICIES = "/security/get_policy_document";
export const BLUEPRINT_CUSTOM_POLICY_DETAILS = "/custom_policy";
export const BLUEPRINT_COST_URL = "/cost/estimate/";
export const CHANGE_CONFIGURATION = "change_configuration";
export const LAUNCH_CONFIGURATIONS_URL = "/launch_configurations";
export const LAUNCH_CONFIGURATION_URL = "/launch_configuration/";
export const TERRAFORM_PRESIGNED_URL = "/terraform/pre-signed-url";
export const TERRAFORM_INFRA_SETUP_URL = "terraform/infra-setup";
export const TERRAFORM_INFRA_CONFIG_URL = "terraform/setup-infra";

//MANIFEST
export const UPLOAD_MANIFEST = "/upload_manifest";
export const MANIFEST_PARAMETERS_URL = "/manifest/parameters";

//FEEDBACK
export const SUPPORT_TICKET_URL = "/customersupport/ticket";

//USERS & ROLES
export const USERS_BASE_URL = "/auth/users";
export const USER_BASE_URL = "/auth/user";
export const ROLES_BASE_URL = "/auth/roles";
export const ROLE_BASE_URL = "/auth/role";
export const USERS_LIST_URL_V2 = "auth/portal/users";
export const MFA_CONFIG_URL = "auth/mfa-config";
export const INITIATE_MFA_SETUP_URL = "auth/initiate-mfa-setup";
export const REGISTER_MFA_DEVICE_URL = "auth/register-mfa-device";
export const VERIFY_MFA_URL = "auth/verify-mfa";
export const RESET_MFA_CONFIG = "reset-mfa-config";
export const RESET_MFA_CONFIG_URL = `auth/{userId}/${RESET_MFA_CONFIG}`;

//DATA REFRESH
export const DATA_REFRESH_BASE_URL = "/datarefresh/";

// GTM - google tag manager
export const GTM_ID = "GTM-NSJPRCZ";

// PAYMENT
export const GET_PAYMENT_INFO = "subscription/plan/{planId}/subscribe";
export const GET_TRANSACTION_DETAILS =
  "subscription/session/{sessionId}/transactions";

// Plan IDS & API Keys
export const STANDARD_PLAN_ID = "1e284856-9393-58f6-8867-9c256e726da3";
export const PREMIUM_PLAN_ID = "e6fc8f84-3151-5e6e-bd4f-fe2381156e7d";
export const FREE_TRIAL_PLAN_ID = "dfd82329-fa4d-52d6-b1f0-f97908f287cc";
export const FREE_TRIAL_ENDED = "643dfe97-42b5-5f5b-a407-af001101ed6a";

//Permissions
export const PERMISSION_BASE_URL = "/permission";

export const GET_HYBRID_ACTIVATION_ROLE_DETAILS =
  "/compute/hybrid_activation_role";
export const GET_ACTIVATION_CODE = "/compute/instance/hybrid_activation_code";

export const PACKAGES = "/packages";
export const PACKAGE_CLOUD_WATCH = "/package/cloudwatchagent/config";
export const ORG_RESOURCE_BASE_URL = "/org/resource/";
export const METRICS = "metrics";
export const LIST = "list";

// RDP
export const RDP_CONFIG_URL = "rdp/config";

// App Rules(IFTTT)
export const APP_RULES = "/rules";
export const EVENT_METADATA = "/rules/events";
export const APP_EXECUTION_HISTORY = "/executions";

// Bots
export const BOTS_BASE_URL = "/bots";
export const INSIGHTS = "insights";
export const REMEDIATE = "remediate";
export const SUPPRESS = "suppress";
export const MANIFEST = "manifest";
export const PREREQUISITES_CHECK = "prerequisites";
export const CONFIGURE = "configure";
export const CONFIG = "config";
export const OFFBOARDING = "offboarding";

// Storage
export const STORAGE_OBJECTS = "/storage/objects";
export const STORAGE = "/storage";
export const FILE = "/file";
export const FOLDER = "/folder";
export const UPLOAD = "/upload";
export const CANCEL = "/cancel";
export const EC2_FILE = "/ec2-file";

//Subscriptions
export const PRODUCT_CATALOG = "/product-catalog";
export const SUBSCRIPTION = "/subscription";
export const PLANS = "/plans";
export const ENTERPRISE_REQUEST = "/enterprise-request";
export const PERMISSION_DETAILS = "/plans-permissions";
// Resource Insights
export const RESOURCE_INSIGHT_SUMMARY_URL = "resource-insight-summary";
export const RESOURCE_INSIGHTS_URL = "resource-insights";
export const RESOURCE_POTENTIAL_COST_SAVINGS_URL = "resource-potential-cost";
export const COST_OPTIMIZATION_SUMMARY = "cost-optimization-summary";

//preference
export const CUSTOMER_PREFERENCE = "/org/customer-preference";

// Admin permission
export const CFN = "/customeraccount/accounts";
export const UPGRADE_TEMPLATE = "upgrade_template";
export const CLOUD_FORMATION_URL =
  "https://console.aws.amazon.com/cloudformation/home?region={region_code}#/stacks/create/review?stackName={stack_name}&templateURL={template_url}";

// Dashboard
export const OPS_SUMMARY = "bot-ops-summary";
export const BOT_SUMMARY = "bot-insight-summary";
export const BOT_INDUSTRY_SUMMARY = "bot-industry-standard";

//Project Requests
export const REQUESTS = "/requests";
export const REQUEST = "/request";

//Elastic IP
export const RELEASE_ELASTIC_IP = "/network/release_elastic_ip";

//ASSESSMENTS
export const WAR_ASSESSMENT_LIST_URL = "/war-assessment/workloads";
export const WAR_ASSESSMENT_METADATA_URL = "/war-assessment/workload/metadata";
export const WAR_ASSESSMENT_Details_URL = "/war-assessment/workload/";
export const WAR_ASSESSMENT_WORKLOAD_BASE_URL = "/war-assessment/workload/";
export const FINDING_SUMMARY = "/finding-summary";
export const FINDINGS_SUMMARY_BY_SEVERITY = "/findings-summary-by-severity";
export const WAR_ASSESSMENT_LIST_STATS_URL =
  "/war-assessment/workload_count_summary";
export const WAR_ASSESSMENT_QUESTIONS_LIST_URL = "/questions";
export const WAR_ASSESSMENT_QUESTION_DETAIL_URL = "/question/";
export const WAR_ASSESSMENT_ANSWER_DETAIL_URL = "/answer";
export const WAR_ASSESSMENT_REPORT_URL = "/report";
export const WAR_ASSESSMENT_CHECKS_EXECUTION = "check-executions";
export const WAR_FINDING_URL = "findings";
export const WAR_ASSESSMENT_SHARE_URL = "/share";
export const WAR_ASSESSMENT_LIST_SHARES_URL = "/shares";
export const WAR_ASSESSMENT_DELETE_SHARE_URL = "/shares/";
export const WAR_ASSESSMENT_UPDATE_SHARE_URL = "/shares/";
export const WAR_ASSESSMENT_MILESTONE_URL = "/milestone";
export const WAR_ASSESSMENT_LIST_MILESTONE_URL = "/milestones";
export const WAR_ASSESSMENT_MILESTONE_BASE_URL = "/milestone/";
export const WAR_INSIGHTS_URL = "/findings";
export const WAR_INSIGHTS_COUNT = "/finding-count";
export const WAR_ASSESSMENT_TOP_CHECKS = "/top-checks";
export const WAR_ASSESSMENT_TOP_RESOURCES = "/top-resources";
export const WAR_ASSESSMENT_RESOURCE_TYPES = "/resource-types";
export const FINDINGS_SUMMARY_BY_BEST_PRACTICE =
  "/finding-summary-by-bestpractice";
export const MILESTONE_SUMMARY_BY_BEST_PRACTICE =
  "/milestone-summary-by-bestpractice";
export const WAR_ASSESSMENT_BEST_PRACTICES = "bestpractices";
export const WAR_UNIQUE_FINDINGS_URL = "/unique-findings";
export const WAR_UNIQUE_MILESTONE_FINDINGS_URL = "/unique-milestone-findings";
export const WAR_UNIQUE_FINDINGS_DETAIL_URL = "check-mapping/";

//ORGANIZATIONS
export const ORG_MGR_LIST_URL = "/auth/organizations"; // for dropdown
export const ORG_MGR_SWITCH_CONTEXT = "/auth/switch-context/organization/";
export const ORG_MGR_AVALILABEL_LIST = "/org/organizations_v1"; // for settings
export const ORG_USER_ROLE_API_URL = "/auth/organizations/{orgId}/roles";
export const ORG_MGR_AUTH_USER_BASE = "/auth/users";
export const ORG_MGR_ASSIGN_ORG = "assign-org";
export const ORG_MGR_UNASSIGN_ORG = "unassign-org";
export const PORTAL_TENANT_LIST = "/org/portal/tenants"; //returns only id and name (for only cloudadmins and businessadmins)

export const getUsersOrganizationUrl = ({ userId }) => {
  return `auth/users/${userId}/organizations`;
};

// MAP PROJECT
export const MAP_HISTORICAL_DATA_URL = "/cost/map/historical-data/validate";
export const MAP_PROJECT_BASE_URL = "/cost/map-projects";
export const MAP_PROJECT_DETAIL_URL = "/cost/map-project/";
export const MAP_PROJECT_CREATE_URL = "/cost/map-project";
export const MAP_PROJECT_COUNT_SUMMARY = "cost/map_projects_count_summary";
export const MP_PROJECT_UPDATE_URL = "/cost/map-project/";
export const BULK_TAG_RESOURCE_URL = "/tag-resources";
//FBP
export const FBP_SERVICE_SCAN = "/customeraccount/fbp-service-scan";
export const FBP_MANAGEMENT_ACCOUNT_DETAILS =
  "/customeraccount/fbp-management-account-details";
export const FBP_CONFIGURE = "/customeraccount/fbp-configure";
export const FBP_ACCOUNT_REGION_DETAILS =
  "/customeraccount/fbp-account-region-details";
export const FBP_MANAGEMENT_ACCOUNTS = "fbp-management-accounts";

export const SUPPORTED_SERVICES_URL =
  "https://mcdev1day2assets.s3.amazonaws.com/MC-Supported-Resource-Types.json";

export const SUPPORT_LINK_ORG_ACCOUNT_PRE_REQUISITE =
  "https://docs.aws.amazon.com/accounts/latest/reference/using-orgs-trusted-access.html";

//SHARE BLUEPRINT CATALOG
export const SHARE_BLUEPRINT_CATALOG_URL = "/share";
export const LIST_BLUEPRINT_SHARES_URL = "/shares";
export const SHARED_BLUEPRINT_CATALOG_LIST_URL = "/blueprints/shared_catalog";
export const ASSIGN_PROJECT_TO_BLUEPRINT_URL = "/blueprints";
export const UNSHARE_BLUEPRINT_CATALOG_URL = "/unshare";

//AWS ACCOUNTS
export const AWS_ACCOUNT_BASE_URL = "/customeraccount/aws/";
export const AWS_ACCOUNT_URL = "/account";
export const CONTROL_TOWER_URL = "control-tower/";
export const CONTROL_TOWER_ENABLED_URL = "/enabled";
export const LIST_ONBOARDED_ACCOUNTS = "list_onboarded_accounts";

//ABC

export const LIST_BILLING_GROUPS_URL = "/billing-group";
export const PRICING_PLAN = "/pricing-plan";
export const AWS_TENANT_ACCOUNT_BASE_URL = "/customeraccount/aws/org/";
export const ABC_TENANT_BASE_URL = "/cost/org/";
export const LIST_BILLING_GROUPS_EXISTING_ACCOUNTS = "/billing-group-accounts";

//COST DASHBOARD
export const SPEND_BREAKDOWN_SUMMARY =
  "/cost/cost-dashboard/spend-breakdown-summary";
export const SPEND_BREAKDOWN_RESOURCE = "/cost/cost-dashboard/resource";
export const COST_AGGREGATE_DASHBOARD = "/cost/cost-dashboard";
export const COST_DASHBOARD_LOOKUP = "/cost/cost-dashboard/lookup";
export const COST_DASHBOARD_SUMMARY = "/cost/cost-dashboard/cost-summary";
export const COST_DASHBOARD_REGION_SUMMARY =
  "/cost/cost-dashboard/region-summary";
export const COST_DASHBOARD_RESOURCE_SUMMARY =
  "/cost/cost-dashboard/resource-summary";
export const COST_DASHBOARD_SPEND_TREND = "/cost/cost-dashboard/spend-trend";
export const SPEND_BREAKDOWN_SUMMARY_RESOURCE_COUNT =
  "/cost/cost-dashboard/resource-count";
export const COST_DASHBOARD_LOOKUP_TAG_KEY =
  "/cost/cost-dashboard/lookup/tag-keys";
export const COST_DASHBOARD_CHARGE_TYPE_SUMMARY = "/cost/cost-by-charge-type";

export const COST_DASHBOARD_TAG_KEY = "/tag-keys";
export const COST_DASHBOARD_TAG_VALUE = "/tag-values";

//aws health events
export const EVENTS = "/events";
export const HEALTH_EVENTS_BASE_URL = "/health/api/v1/events";
export const POLICY_BASE_URL = "/health/api/v1/policies";
export const EVENT_TIMELINES = "/{eventId}/event-timelines";
export const AFFECTED_RESOURCES = "/{eventId}/affected-resources";
export const HEALTH_EVENTS_SUMMARY = "/summary";
export const EVENTS_BREAKDOWN_SUMMARY = "/breakdown-summary";
export const CONFIGURED_ACCOUNTS = "/{policyId}/configs";
export const CONFIGURATION_ISSUES = "/{policyId}/executions/failures";
export const COUNT_OF_CONFIGURATION_FAILED_ACCOUNTS = "/count";

//CREDITS
export const CREDITS_SUMMARY = `/cost/org/{orgId}/credits/breakdown-summary`;
export const CREDITS_LIST = `/cost/org/{orgId}/credits`;
export const CUSTOM_LINE_ITEMS = "/custom-line-items";
export const CUSTOM_LINE_ITEM = "/custom-line-item";
export const ASSOCIATE_CUSTOM_LINE_ITEMS = "/associated-custom-line-items";
export const COUNT = "/count";
export const UNASSIGN_CREDIT = "cost/org/{orgId}/credits/{creditId}/unassign";
export const ASSIGN_CREDIT = "cost/org/{orgId}/credits/{creditId}/assign";
export const BULK_ASSIGN_CREDIT = "cost/org/{orgId}/credits/bulk-assign";
export const BULK_UNASSIGN_CREDIT = "cost/org/{orgId}/credits/bulk-unassign";
export const TOTAL_CREDITS_RECEIVED = "cost/org/{orgId}/credits/summary";
export const CUSTOM_LINE_ITEM_ARN =
  "/app/billing-setup/credits/custom-line-items/custom-line-item";

export const ACCOUNTS = "/accounts";

//MARKETPLACE CHARGES
export const MARKETPLACE_CHARGES_SUMMARY =
  "/cost/org/{orgId}/credits/marketplace/breakdown-summary";
export const MARKETPLACE_CHARGES_LIST = "/cost/org/{orgId}/credits/marketplace";
export const DISCOUNT_MARKETPLACE_CHARGE =
  "/cost/org/{orgId}/credits/marketplace/{creditId}/assign";
export const BULK_DISCOUNT_MARKETPLACE_CHARGE =
  "/cost/org/{orgId}/credits/marketplace/bulk-assign";
export const BULK_REMOVE_DISCOUNT_MARKETPLACE_CHARGE =
  "/cost/org/{orgId}/credits/marketplace/bulk-unassign";
export const MARKETPLACE_TOTAL_CREDITS =
  "/cost/org/{orgId}/credits/marketplace/summary";
export const MARKETPLACE_UNASSIGN_CREDIT =
  "cost/org/{orgId}/credits/marketplace/{creditId}/unassign";
export const MARKETPLACE_EDIT_CREDIT =
  "cost/org/{orgId}/custom-line-items/{customLineItemId}";

//SUPPORT CHARGES
export const SUPPORT_CHARGES_SUMMARY =
  "/cost/org/{orgId}/support-charges/breakdown-summary";
export const SUPPORT_CHARGES_LIST = "/cost/org/{orgId}/support-charges";
export const TOTAL_SUPPORT_CHARGES_RECEIVED =
  "/cost/org/{orgId}/support-charges/summary";
export const ASSIGN_SUPPORT_CHARGE =
  "cost/org/{orgId}/support-charges/{supportChargeId}/assign";
export const UNASSIGN_SUPPORT_CHARGE =
  "cost/org/{orgId}/support-charges/{supportChargeId}/unassign";
export const BULK_UNASSIGN_SUPPORT_CHARGE =
  "/cost/org/{orgId}/support-charges/bulk-unassign";
export const BULK_ASSIGN_SUPPORT_CHARGES =
  "/cost/org/{orgId}/support-charges/bulk-assign";

// FEDERATED ACCESS
export const FEDERATED_ACCESS_ACCOUNTS_BASE_PATH =
  "/customeraccount/api/v1/faa-roles/";
export const CONFIGURE_FEDERATED_ACCOUNT_ACCESS_LINK = "create-signin-url";

// MANAGEMENT REPORTS
export const MANAGEMENT_REPORTS_EXPORTS = "reports/msp-exports";
export const MSP_COST_REPORTS = "/cost/reports/initiate_msp_report";

// AZURE COST OPTIMIZATION
export const AZURE_COST_OPTIMIZATION_SPEND_AND_SAVINGS_BREAKDOWN =
  "/optimizations/api/v1/azure/cost/subscription/savings";
export const AZURE_COST_OPTIMIZATION_BASE_URL = "/optimizations/api/v1/azure/cost";
export const AZURE_COST_OPTIMIZATION_OVERVIEW_SUMMARY = "/overview-summary";
export const TOP_5_OPPORTUNITIES =
  "/opportunities/{opportunityType}/service-type/top-n";
export const AZURE_OPTIMIZATION_FINDINGS_BREAKDOWN = "/findings";
export const AZURE_SPEND_VS_POTENTIAL_SAVINGS_CHART = "/savings";
export const AZURE_COST_OPTIMIZATION_COST_OPPORTUNITIES =
  "/optimizations/api/v1/azure/cost/opportunities";
export const OPPORTUNITIES_SUMMARY = "/opportunities/{OpportunityType}/summary";
export const AZURE_COST_OPPORTUNITIES_ACTION =
"optimizations/api/v1/azure/cost/recommendations";

export const LIVE_COACHING_FEEDBACK_API_URL = "/copilot-feedback/message/";
