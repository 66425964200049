import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Navigate,  } from "react-router-dom";
import { withRouter } from "@hocs/withRouter";
import { Alert, Col, Container, FormGroup, Input, InputGroup } from "reactstrap";
import Widget from "../../../../components/Widget";
import { resetPassword } from "../../../../modules/authentication/actions/auth";
import { checkPasswordVisibility } from "../../../../utils/common";
import s from "./ResetPassword.module.scss";

class ResetPassword extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  static isAuthenticated(token) {
    if (!token) return;
    return true;
  }

  constructor(props) {
    super(props);

    this.state = {
      newPassword: "",
      validPassword: true,
      retypeNewPassword: "",
      validRetypeNewPassword: true,
    };
  }

  changeNewPassword = (event) => {
    this.setState({
      newPassword: event.target.value,
      validPassword: checkPasswordVisibility(event.target.value),
    });
    this.state.retypeNewPassword &&
      this.checkRetypePasswordValidity(event.target.value);
  };

  checkRetypePasswordValidity = (newPassword) => {
    this.setState({
      validRetypeNewPassword: this.state.retypeNewPassword === newPassword,
    });
  };

  confirmNewPassword = (event) => {
    this.setState({
      retypeNewPassword: event.target.value,
      validRetypeNewPassword: this.state.newPassword === event.target.value,
    });
  };

  resetPassword = (e) => {
    e.preventDefault();
    this.props.resetPassword({
      Session: this.props.newPasswordRequiredData.session,
      Username: this.props.newPasswordRequiredData.username,
      Password: this.state.newPassword,
    });
  };

  render() {
    const {
      t,
      isResettingPassword,
      resetErrorMessage,
      newPasswordRequiredData,
    } = this.props;
    const {
      newPassword,
      validPassword,
      retypeNewPassword,
      validRetypeNewPassword,
    } = this.state;

    const disableResetPasswordBtn =
      isResettingPassword ||
      !newPassword ||
      !validPassword ||
      !retypeNewPassword ||
      !validRetypeNewPassword ||
      newPassword !== retypeNewPassword;
    const { from } = this.props.location.state || {
      from: { pathname: "/app" },
    }; // eslint-disable-line

    // cant access login page while logged in
    if (
      ResetPassword.isAuthenticated(localStorage.getItem("token")) ||
      !newPasswordRequiredData
    ) {
      return <Navigate to={from} />;
    }

    return (
      <div className="auth-container">
        <Container className={s.authContainer}>
          <Widget
            className="widget padding-30px bs4-mx-auto"
            title={
              <h4 className="bs4-mt-3 bs4-mb-2 bs4-ml-2 orange-text">
                {t("resetPassword")}
              </h4>
            }
            logo={
              <a
                href="https://www.montycloud.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  className="logo"
                  src="/images/DAY2Logo.jpg"
                  alt="MontyCloud"
                ></img>
              </a>
            }
          >
            <form className="mt" onSubmit={(e) => this.resetPassword(e)}>
              {resetErrorMessage ? (
                <Alert className="alert-sm mc-text-sm-regular" color="danger">
                  {resetErrorMessage}
                </Alert>
              ) : (
                <Alert className="alert-sm mc-text-sm-regular" color="warning">
                  {t("changePasswordMessage")}
                </Alert>
              )}
              <FormGroup row className="bs4-mt-4">
                <Col>
                  <InputGroup>
                    <Input
                      className="form-control mc-text-sm-regular"
                      value={newPasswordRequiredData.username}
                      readOnly
                      type="email"
                      required
                      name="email"
                      placeholder={t("email")}
                    />
                    <span className="input-group-text">
                      <i className="fa fa-envelope-o" />
                    </span>
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup row className="bs4-mt-4">
                <Col>
                  <InputGroup>
                    <Input
                      className={
                        !validPassword
                          ? `form-control ${s.invalidInput}`
                          : `form-control`
                      }
                      value={newPassword}
                      onChange={this.changeNewPassword}
                      type="password"
                      required
                      name="password"
                      placeholder={t("newPassword")}
                    />
                    <span className="input-group-text">
                      <i className="fi flaticon-locked-4" />
                    </span>
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup row className="bs4-mt-4">
                <Col>
                  <InputGroup>
                    <Input
                      className={
                        !validRetypeNewPassword
                          ? `form-control ${s.invalidInput}`
                          : `form-control`
                      }
                      value={retypeNewPassword}
                      onChange={this.confirmNewPassword}
                      type="password"
                      required
                      name="retypePassword"
                      disabled={!newPassword || !validPassword}
                      placeholder={t("confirmPassword")}
                    />
                    <span className="input-group-text">
                      <i className="fi flaticon-locked-4" />
                    </span>
                  </InputGroup>
                  {!validRetypeNewPassword && (
                    <small className={`bs4-mb-0 bs4-mt-1 ${s.PwdText}`}>
                      {t("passwordsMismatchMsg")}
                    </small>
                  )}
                </Col>
              </FormGroup>
              <small className={classNames(s.PwdText, "mc-text-xsm-regular")}>
                {t("passwordMsg")}
              </small>

              <div className="clearfix bs4-mt-2">
                <div className="btn-toolbar float-right">
                  <button
                    type="submit"
                    href="/app"
                    disabled={disableResetPasswordBtn}
                    className="btn btn-primary btn-sm mc-text-base-regular"
                  >
                    {isResettingPassword ? t("loading") : t("resetPassword")}
                  </button>
                </div>
              </div>
            </form>
          </Widget>
        </Container>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  const {
    isResettingPassword,
    resetErrorMessage,
    passwordReset,
    newPasswordRequiredData,
  } = auth;
  return {
    isResettingPassword,
    resetErrorMessage,
    passwordReset,
    newPasswordRequiredData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    resetPassword,
  })(withTranslation(["forgotPassword"])(ResetPassword))
);
