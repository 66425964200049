import { CERTIFICATE } from "./app-constants.json";

export const montycloudSupportHomeLink =
  "https://support.montycloud.com/support/home";
export const montycloudSupportLink =
  "https://support.montycloud.com/support/solutions";
export const configureSSOLink =
  "https://support.montycloud.com/a/solutions/articles/62000206236";
export const domainHelpLink =
  "https://support.montycloud.com/a/solutions/articles/62000206240";
export const samlHelpLink =
  "https://support.montycloud.com/a/solutions/articles/62000206239";
export const roleHelpLink =
  "https://support.montycloud.com/support/solutions/articles/62000206242";
export const metadataHelpLink =
  "https://support.montycloud.com/a/solutions/articles/62000206238";
export const ssoDetailsHelpLink =
  "https://support.montycloud.com/a/solutions/articles/62000206237";
export const logArticleLink =
  "https://support.montycloud.com/support/solutions/articles/62000223717";
export const costSupportLink =
  "https://support.montycloud.com/support/solutions/articles/62000210457";
export const costExplorerSupportLink =
  "https://support.montycloud.com/support/solutions/articles/62000213739";
export const permissionsLink =
  "https://montycloud.freshdesk.com/a/solutions/articles/62000213248/";
export const montycloudCloudTrailLink =
  "https://support.montycloud.com/support/solutions/articles/62000225910-how-to-enable-montycloud-audit-event-logging-in-aws-cloudtrail-lake";
export const azurePermissionsLink =
  "https://support.montycloud.com/en/support/solutions/articles/62000227457-day2%E2%84%A2-azure-permissions";
export const controlTowerLink = "https://aws.amazon.com/controltower/";

// MontyCloud Website Links
export const montyCloudWebsiteLink = "https://www.montycloud.com/";
export const riverMeadowWebsiteLink = "https://www.rivermeadow.com/";
export const sluCaseStudyLink =
  "https://www.montycloud.com/saint-louis-university/";
export const jgiCaseStudyLink =
  "https://www.montycloud.com/jain-group-of-institutions/";
export const leadSquaredCaseStudyLink =
  "https://www.montycloud.com/leadsquared-saas-crm-platform/";
export const partnershipLink = "https://www.montycloud.com/aws/";
export const purchasePlanTermsLink =
  "https://www.montycloud.com/platform-terms/";
export const purchasePrivacyPolicyLink =
  "https://www.montycloud.com/platform-privacy-policy";

export const getResourceLink = (resourceType) => {
  let link = "";
  switch (resourceType) {
    case CERTIFICATE:
      link =
        "https://docs.aws.amazon.com/acm/latest/userguide/acm-regions.html";
      break;
    default:
      break;
  }
  return link;
};

export const calendlyRescheduleUrl = "https://calendly.com/reschedulings/";
export const calendlyCancelUrl = "https://calendly.com/cancellations/";

// AWS Support links
export const awsWellArchitectedUrl =
  "https://aws.amazon.com/architecture/well-architected/";

  export const MC_TERMS_AND_CONDITIONS = "https://montycloud.com/gen-ai-addendum";